import React, { useRef }  from 'react';
import './App.css';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '0',
    // left: '75%',
    height: '100%',
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
    bottom: 'auto',
  },
};

function App() {
  const video = useRef();
  const [modalIsOpen, setIsOpen] = React.useState(true);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    console.log("click outside")
    video.current.play();
    setIsOpen(false);

  }


  function mouseMoving(event){
     document.querySelector('.App').style.backgroundColor = `rgb(${event.pageX % 255} ${event.pageY % 255} 100)`
  }

  return (
    <div className="App" onMouseMove={mouseMoving}>
      <video ref={video} autoPlay loop src="./rick_roll.mp4" type="video/mp4"> </video>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2  class="m-4">Hello - we take cookies and privacy seriously</h2>
        <div class="m-4" >Our website uses cookies to enhance your browsing experience, provide personalized content and advertisements, and analyze our traffic. Cookies are small text files stored on your device when you visit our site. They help us remember your preferences, understand how you interact with our website, and improve our services based on your feedback. By continuing to browse or use our site, you agree to the use of cookies as described in this policy.
        </div>
        <div class="m-4">
We use both session cookies, which expire once you close your browser, and persistent cookies, which remain on your device until you delete them or they expire. These cookies are essential for the website's functionality, such as maintaining your login session and storing items in your shopping cart. Additionally, we use analytical cookies to collect information on how visitors use our site, enabling us to enhance your experience and troubleshoot any issues. Advertising cookies are also employed to deliver relevant ads and measure their effectiveness.
        </div>
        <div class="m-4">
You have the right to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. Please note that if you choose to decline cookies, you may not be able to fully experience all the features of our website. For more detailed information about our cookie usage and how you can manage your preferences, please visit our Privacy Policy or contact our support team.</div>
        
        <button onClick={closeModal} class="btn btn-success m-4">Accept Cookies</button>
        <button onClick={closeModal} class="btn btn-default m-4">Refuse Cookies</button>

      </Modal>
    </div>
  );
}

export default App;
